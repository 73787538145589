/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { QueryClient, UseQueryOptions } from '@tanstack/react-query'

import {
  Claim,
  ClaimsResponse,
  Config,
  GetHooksResponse,
  ListStakersResponse,
  OwnershipForAddr,
  StakedBalanceAtHeightResponse,
  StakedValueResponse,
  TotalStakedAtHeightResponse,
  TotalValueResponse,
} from '@dao-dao/types/contracts/Cw20Stake'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { Cw20StakeQueryClient } from '../../../contracts/Cw20Stake'
import { indexerQueries } from '../indexer'
import { cw20StakeExtraQueries } from './Cw20Stake.extra'
import { oraichainCw20StakingExtraQueries } from './OraichainCw20Staking.extra'

export const cw20StakeQueryKeys = {
  contract: [
    {
      contract: 'cw20Stake',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...cw20StakeQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  stakedBalanceAtHeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'staked_balance_at_height',
        args,
      },
    ] as const,
  totalStakedAtHeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'total_staked_at_height',
        args,
      },
    ] as const,
  stakedValue: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'staked_value',
        args,
      },
    ] as const,
  totalValue: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'total_value',
        args,
      },
    ] as const,
  getConfig: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'get_config',
        args,
      },
    ] as const,
  claims: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'claims',
        args,
      },
    ] as const,
  getHooks: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'get_hooks',
        args,
      },
    ] as const,
  listStakers: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'list_stakers',
        args,
      },
    ] as const,
  ownership: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...cw20StakeQueryKeys.address(chainId, contractAddress)[0],
        method: 'ownership',
        args,
      },
    ] as const,
}
export const cw20StakeQueries = {
  stakedBalanceAtHeight: <TData = StakedBalanceAtHeightResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: Cw20StakeStakedBalanceAtHeightQuery<TData>
  ): UseQueryOptions<StakedBalanceAtHeightResponse, Error, TData> => ({
    queryKey: cw20StakeQueryKeys.stakedBalanceAtHeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      // If Oraichain proxy, get staking token and pass to indexer query.
      let oraichainStakingToken: string | undefined
      const isOraichainProxy = await queryClient.fetchQuery(
        cw20StakeExtraQueries.isOraichainProxySnapshotContract(queryClient, {
          chainId,
          address: contractAddress,
        })
      )
      if (isOraichainProxy) {
        oraichainStakingToken = (
          await queryClient.fetchQuery(
            cw20StakeExtraQueries.oraichainProxySnapshotConfig(queryClient, {
              chainId,
              address: contractAddress,
            })
          )
        ).asset_key
      }

      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'cw20Stake/stakedBalanceAtHeight',
            args: {
              address: args.address,
              ...(oraichainStakingToken && { oraichainStakingToken }),
            },
            ...(args.height && { block: { height: args.height } }),
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new Cw20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).stakedBalanceAtHeight({
        address: args.address,
        height: args.height,
      })
    },
    ...options,
  }),
  totalStakedAtHeight: <TData = TotalStakedAtHeightResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: Cw20StakeTotalStakedAtHeightQuery<TData>
  ): UseQueryOptions<TotalStakedAtHeightResponse, Error, TData> => ({
    queryKey: cw20StakeQueryKeys.totalStakedAtHeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      // If Oraichain proxy, get staking token and pass to indexer query.
      let oraichainStakingToken: string | undefined
      const isOraichainProxy = await queryClient.fetchQuery(
        cw20StakeExtraQueries.isOraichainProxySnapshotContract(queryClient, {
          chainId,
          address: contractAddress,
        })
      )
      if (isOraichainProxy) {
        oraichainStakingToken = (
          await queryClient.fetchQuery(
            cw20StakeExtraQueries.oraichainProxySnapshotConfig(queryClient, {
              chainId,
              address: contractAddress,
            })
          )
        ).asset_key
      }

      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'cw20Stake/totalStakedAtHeight',
            args: {
              ...(oraichainStakingToken && { oraichainStakingToken }),
            },
            ...(args.height && { block: { height: args.height } }),
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new Cw20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).totalStakedAtHeight({
        height: args.height,
      })
    },
    ...options,
  }),
  stakedValue: <TData = StakedValueResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: Cw20StakeStakedValueQuery<TData>
  ): UseQueryOptions<StakedValueResponse, Error, TData> => ({
    queryKey: cw20StakeQueryKeys.stakedValue(chainId, contractAddress, args),
    queryFn: async () => {
      // Oraichain proxy handles passing the query through.
      const isOraichainProxy = await queryClient.fetchQuery(
        cw20StakeExtraQueries.isOraichainProxySnapshotContract(queryClient, {
          chainId,
          address: contractAddress,
        })
      )
      if (isOraichainProxy) {
        return {
          value: (
            await queryClient.fetchQuery(
              cw20StakeQueries.stakedBalanceAtHeight(queryClient, {
                chainId,
                contractAddress,
                args,
              })
            )
          ).balance,
        }
      }

      try {
        // Attempt to fetch data from the indexer.
        return {
          value: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'cw20Stake/stakedValue',
              args,
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new Cw20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).stakedValue({
        address: args.address,
      })
    },
    ...options,
  }),
  totalValue: <TData = TotalValueResponse>(
    queryClient: QueryClient,
    { chainId, contractAddress, options }: Cw20StakeTotalValueQuery<TData>
  ): UseQueryOptions<TotalValueResponse, Error, TData> => ({
    queryKey: cw20StakeQueryKeys.totalValue(chainId, contractAddress),
    queryFn: async () => {
      // Oraichain proxy handles passing the query through.
      const isOraichainProxy = await queryClient.fetchQuery(
        cw20StakeExtraQueries.isOraichainProxySnapshotContract(queryClient, {
          chainId,
          address: contractAddress,
        })
      )
      if (isOraichainProxy) {
        return {
          total: (
            await queryClient.fetchQuery(
              cw20StakeQueries.totalStakedAtHeight(queryClient, {
                chainId,
                contractAddress,
                args: {},
              })
            )
          ).total,
        }
      }

      try {
        // Attempt to fetch data from the indexer.
        return {
          total: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'cw20Stake/totalValue',
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new Cw20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).totalValue()
    },
    ...options,
  }),
  getConfig: <TData = Config>(
    queryClient: QueryClient,
    { chainId, contractAddress, options }: Cw20StakeGetConfigQuery<TData>
  ): UseQueryOptions<Config, Error, TData> => ({
    queryKey: cw20StakeQueryKeys.getConfig(chainId, contractAddress),
    queryFn: async () => {
      const isOraichainProxy = await queryClient.fetchQuery(
        cw20StakeExtraQueries.isOraichainProxySnapshotContract(queryClient, {
          chainId,
          address: contractAddress,
        })
      )

      // Oraichain proxy handles passing the query through.
      if (!isOraichainProxy) {
        try {
          // Attempt to fetch data from the indexer.
          return await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'cw20Stake/config',
            })
          )
        } catch (error) {
          console.error(error)
        }
      }

      // If indexer query fails, fallback to contract query.
      return new Cw20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).getConfig()
    },
    ...options,
  }),
  claims: <TData = ClaimsResponse>(
    queryClient: QueryClient,
    { chainId, contractAddress, args, options }: Cw20StakeClaimsQuery<TData>
  ): UseQueryOptions<ClaimsResponse, Error, TData> => ({
    queryKey: cw20StakeQueryKeys.claims(chainId, contractAddress, args),
    queryFn: async () => {
      // Convert Oraichain lock infos to claims.
      const isOraichainProxy = await queryClient.fetchQuery(
        cw20StakeExtraQueries.isOraichainProxySnapshotContract(queryClient, {
          chainId,
          address: contractAddress,
        })
      )
      if (isOraichainProxy) {
        const { asset_key, staking_contract } = await queryClient.fetchQuery(
          cw20StakeExtraQueries.oraichainProxySnapshotConfig(queryClient, {
            chainId,
            address: contractAddress,
          })
        )
        const { lock_infos } = await queryClient.fetchQuery(
          oraichainCw20StakingExtraQueries.listAllLockInfos(queryClient, {
            chainId,
            address: staking_contract,
            stakerAddr: args.address,
            stakingToken: asset_key,
          })
        )

        return {
          claims: lock_infos.map(
            ({ amount, unlock_time }): Claim => ({
              amount,
              release_at: {
                // Convert seconds to nanoseconds.
                at_time: (BigInt(unlock_time) * BigInt(1e9)).toString(),
              },
            })
          ),
        }
      }

      try {
        // Attempt to fetch data from the indexer.
        return {
          claims: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'cw20Stake/claims',
              args,
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new Cw20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).claims({
        address: args.address,
      })
    },
    ...options,
  }),
  getHooks: <TData = GetHooksResponse>({
    chainId,
    contractAddress,
    options,
  }: Cw20StakeGetHooksQuery<TData>): UseQueryOptions<
    GetHooksResponse,
    Error,
    TData
  > => ({
    queryKey: cw20StakeQueryKeys.getHooks(chainId, contractAddress),
    queryFn: async () => {
      return new Cw20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).getHooks()
    },
    ...options,
  }),
  listStakers: <TData = ListStakersResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: Cw20StakeListStakersQuery<TData>
  ): UseQueryOptions<ListStakersResponse, Error, TData> => ({
    queryKey: cw20StakeQueryKeys.listStakers(chainId, contractAddress, args),
    queryFn: async () => {
      // Oraichain has their own interface.
      const isOraichainProxy = await queryClient.fetchQuery(
        cw20StakeExtraQueries.isOraichainProxySnapshotContract(queryClient, {
          chainId,
          address: contractAddress,
        })
      )
      if (isOraichainProxy) {
        return { stakers: [] }
      }

      try {
        // Attempt to fetch data from the indexer.
        return {
          stakers: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'cw20Stake/listStakers',
              args,
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new Cw20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).listStakers({
        limit: args.limit,
        startAfter: args.startAfter,
      })
    },
    ...options,
  }),
  ownership: <TData = OwnershipForAddr>(
    queryClient: QueryClient,
    { chainId, contractAddress, options }: Cw20StakeOwnershipQuery<TData>
  ): UseQueryOptions<OwnershipForAddr, Error, TData> => ({
    queryKey: cw20StakeQueryKeys.ownership(chainId, contractAddress),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'cw20Stake/ownership',
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new Cw20StakeQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).ownership()
    },
    ...options,
  }),
}
export interface Cw20StakeReactQuery<TResponse, TData = TResponse> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface Cw20StakeOwnershipQuery<TData>
  extends Cw20StakeReactQuery<OwnershipForAddr, TData> {}
export interface Cw20StakeListStakersQuery<TData>
  extends Cw20StakeReactQuery<ListStakersResponse, TData> {
  args: {
    limit?: number
    startAfter?: string
  }
}
export interface Cw20StakeGetHooksQuery<TData>
  extends Cw20StakeReactQuery<GetHooksResponse, TData> {}
export interface Cw20StakeClaimsQuery<TData>
  extends Cw20StakeReactQuery<ClaimsResponse, TData> {
  args: {
    address: string
  }
}
export interface Cw20StakeGetConfigQuery<TData>
  extends Cw20StakeReactQuery<Config, TData> {}
export interface Cw20StakeTotalValueQuery<TData>
  extends Cw20StakeReactQuery<TotalValueResponse, TData> {}
export interface Cw20StakeStakedValueQuery<TData>
  extends Cw20StakeReactQuery<StakedValueResponse, TData> {
  args: {
    address: string
  }
}
export interface Cw20StakeTotalStakedAtHeightQuery<TData>
  extends Cw20StakeReactQuery<TotalStakedAtHeightResponse, TData> {
  args: {
    height?: number
  }
}
export interface Cw20StakeStakedBalanceAtHeightQuery<TData>
  extends Cw20StakeReactQuery<StakedBalanceAtHeightResponse, TData> {
  args: {
    address: string
    height?: number
  }
}
